import React, { useContext, useEffect, useState, useCallback } from "react"
import Layout from "../layout.js"
import Pagenation from "components/tools/pagination.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getContactListAsync } from "store/modules/postReducer.js"
import * as styles from "./list.module.css"
import { faq } from "utils/data"
import { getDateYMD, getThumb, download } from "utils"
import api from "apis"
import CustomCalendar from "components/tools/CustomCalendar"
import { textEdit } from "utils"
const List = props => {
  const [currentPage, setCurrentPage] = useState(1)
  // const [answerList, setAnswerList] = useState([])
  const [answer, setAnswer] = useState(null)
  const [answerFile, setAnswerFile] = useState(null)
  const [currentQmenu, setCurrentQmenu] = useState(null)
  const [time, setTime] = useState({
    begin: 0,
    end: 0,
  })

  const dispatch = useDispatch()
  const getContactList = useCallback(
    (currentPage, pagePostSize, order, time) =>
      dispatch(getContactListAsync(currentPage, pagePostSize, order, time)),
    [dispatch]
  )
  const pagePostSize = 13

  useEffect(() => {
    ;(async () => {
      getContactList(currentPage, pagePostSize, undefined, time)
    })()
  }, [currentPage, time])

  const { contactList, contactTotal } = useSelector(
    ({ postReducer: { contact } }) => contact,
    shallowEqual
  )

  const AnswerList = ({ content }) => {
    const [open, setOpen] = useState(false)
    const [answers, setAnswers] = useState(null)
    const [userQuestion, setUserQuestion] = useState(null)

    const attachFile = (files = [], currentTarget) => {
      if (files) {
        const downLoad = file =>
          download(getThumb(file.data_uuid), file.data_name)

        if (currentTarget) {
          const filetag = currentTarget.nextSibling.querySelector(
            "div[name=file] > div:nth-child(2)"
          )
          for (let i = 0; i < files.length; i++) {
            let div = document.createElement("div")
            div.textContent = files[i].data_name
            div.onclick = () => downLoad(files[i])
            filetag.appendChild(div)
          }

          // filetag.onclick = () => downLoad(files[0])
        }
        // else {
        //   //답변의 첨부파일 부분
        //   //답변에 첨부파일은 삭제하는 것으로 변경됨(22.5.12일에)
        //   setAnswerFile(
        //     files.map(file => (
        //       <div onClick={() => downLoad(file)}>{file.data_name}</div>
        //     ))
        //   )
        // }
      }
    }

    const onClick = async ({ currentTarget }) => {
      const result = await api.getContactDetail(
        currentTarget.getAttribute("name")
      )
      const answer = await api.getAnswerDetail(result.answer[0])
      setOpen(state => !state)
      setAnswers(answer?.answer)
      setUserQuestion(result.bodyText)

      if (open) {
        // console.log(120120, currentTarget)
      } else {
        attachFile(result.Attach, currentTarget)
        // attachFile(answer?.answer.Attach)
      }
    }

    return (
      <>
        <div
          onClick={onClick}
          className={styles.qList}
          name={content.question_no}
        >
          <div style={{ fontWeight: 500 }}>
            <div>{faq.get(content.category)}</div>
            <div>{content.title}</div>
          </div>
          <div style={{ fontWeight: 500 }}>
            <div>{getDateYMD(content.registerTime, "YYYY.MM.DD")}</div>
            {content?.answer_count > 0 ? (
              <div>답변완료</div>
            ) : (
              <div
                style={{
                  color: "#E95C63",
                }}
              >
                미답변
              </div>
            )}
          </div>
        </div>
        {open ? (
          <div
            className={styles.anser}
            name={content.question_no}
            style={{ fontWeight: 400 }}
          >
            <div name={"qContent"}>{userQuestion ?? null}</div>
            <div name={"file"}>
              <div>첨부파일</div>
              <div id="q_file" style={{ cursor: "pointer" }}></div>
            </div>
            {answers ? (
              <div name={"aContent"}>
                <div>
                  <div>{textEdit(answers?.bodyText)}</div>
                  <div>
                    <div>{getDateYMD(answers?.registerTime, "YYYY.MM.DD")}</div>
                    <div>피치서가</div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </>
    )
  }

  return (
    <Layout customStyle={{ display: "none" }}>
      <div className={styles.con}>
        <div className={styles.title}>나의 문의 내역</div>
        <CustomCalendar time={time} setTime={setTime} />
        <div style={{ marginTop: "26.5px" }}>
          <div className={styles.qList}>
            <div>
              <div>문의유형</div>
              <div>내용</div>
            </div>
            <div>
              <div>작성일</div>
              <div>처리상태</div>
            </div>
          </div>
          {contactList.map(content => (
            <AnswerList content={content} key={content.question_no} />
          ))}
        </div>
      </div>
      <div>
        <Pagenation
          total={contactTotal}
          pagePostSize={pagePostSize}
          pageSize={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Layout>
  )
}

export default List
