import React, { useState } from "react"
import * as styles from "./customCalendar.module.css"
import "./custom.css"
import calIcon from "images/calIcon.png"
import dayjs from "dayjs"
import { getDateYMD } from "utils"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import produce from "immer"
const CustomCalendar = ({ time, setTime }) => {
  //
  const now = dayjs().add(1, "day").unix()
  const $3_month_ago = dayjs().subtract(3, "month").unix()

  const $1_month_ago = dayjs().subtract(1, "month").unix()

  const $1_week_ago = dayjs().subtract(1, "week").unix()

  const menu = ["1주일", "1개월", "3개월", "전체"]

  const setter = (begin, end) => {
    setTime({
      begin,
      end,
    })
  }

  const onTimeMenu = ({ currentTarget }, v) => {
    switch (v) {
      case "1주일":
        setter($1_week_ago, now)
        break
      case "1개월":
        setter($1_month_ago, now)
        break
      case "3개월":
        setter($3_month_ago, now)
        break
      case "전체":
        setter(0, 0)
        break
      default:
        break
    }

    // if (curTimeMenu) curTimeMenu.style.border = "1px solid #dfdfdf"
    // currentTarget.style.border = "1px solid #E95C63"

    const [tag] = document.getElementsByClassName(`${styles.on}`)

    if (tag) tag.classList.replace(`${styles.on}`, `${styles.off}`)
    currentTarget.classList.replace(`${styles.off}`, `${styles.on}`)
  }

  const [currentmenu, setCurrentmenu] = useState(null)

  const hover = ({ currentTarget }) => {
    const tag = currentTarget.querySelector(`.calenderBox`)
    setCurrentmenu(tag)
    if (tag) tag.style.display = "inline-block"
  }

  const unhover = () => {
    if (currentmenu) currentmenu.style.display = "none"
  }

  return (
    <div className={styles.timeMenu}>
      {menu.map((v, i) => (
        <div
          key={i}
          onClick={e => onTimeMenu(e, v)}
          className={v === "전체" ? `${styles.on}` : `${styles.off}`}
        >
          {v}
        </div>
      ))}
      <div className={styles.main}>
        <div onMouseOver={hover} onMouseLeave={unhover}>
          <div className={styles.date}>
            {time.begin ? getDateYMD(time.begin, "YYYY.MM.DD") : ""}
          </div>
          <img src={calIcon} />
          <div style={{ position: "absolute" }}>
            <div className={"calenderBox"}>
              <Calendar
                onChange={date => {
                  setTime({
                    ...time,
                    begin: dayjs(date).unix(),
                  })
                }}
                calendarType="US"
              />
            </div>
          </div>
        </div>
        <div>~</div>
        <div onMouseOver={hover} onMouseLeave={unhover}>
          <div className={styles.date}>
            {time.end
              ? dayjs.unix(time.end).subtract(1, "day").format("YYYY.MM.DD")
              : ""}
          </div>
          <img src={calIcon} />
          <div style={{ position: "absolute" }}>
            <div className={"calenderBox"}>
              <Calendar
                onChange={date => {
                  setTime({
                    ...time,
                    end: dayjs(date).add(1, "day").unix(),
                  })
                }}
                calendarType="US"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomCalendar

// const checkSaturday = date => {
//   const days = date.split(" ")

//   if (days[0] === "Sat") {
//     const dayButton = document.querySelectorAll(
//       `.react-calendar__month-view__days > button > abbr`
//     )
//     dayButton.forEach(button => {
//       const abbrDay = button.ariaLabel

//       // console.log(abbrDay.split(" ")[1])
//       // console.log(Number(days[2]))
//       if (Number(days[2]) === `${abbrDay.split(" ")[1]}일`) {
//         console.log(abbrDay.split(" ")[1])
//         console.log(Number(days[2]))
//         console.log("saturday@!")
//         abbrDay.style.color = "blue"
//       }
//     })
//   }
// }
