import React, { useEffect, useCallback } from "react"
import * as styles from "components/common/footer.module.css"
// import sampleimg from "images/sampleimg.png"
import Logo from "components/tools/logo.js"
import api from "apis"
import { getFooterAsync } from "store/modules/footerReducer.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import downIcon from "images/downIcon.png"
import { navigate } from "gatsby"

import { face, ins, kao, naver, youtube } from "images"
import { term } from "utils/data"
const Footer = () => {
  const dispatch = useDispatch()

  const getFooter = useCallback(() => dispatch(getFooterAsync()), [dispatch])

  const { footer, familes } = useSelector(
    ({ footerReducer: { data } }) => data,
    shallowEqual
  )

  useEffect(() => {
    ;(async () => {
      getFooter()
    })()
  }, [])
  // company_address: "주소"
  // company_ceo: "대표"
  // company_email: "peach@gggg.com"
  // company_name: "피치서가"
  // company_officer: "정보보호 책임자"
  // company_reg_number: "1111"
  // customer_email: "customer@gggg.com"
  // customer_fax: "1233333"
  // customer_operation: "상시(24시)"
  // customer_phone: "028877777"
  // customer_sale_number: "1121223"
  // inform_copyright: "blabla"
  // inform_email: "blablablab"
  // registerTime: 1641178311
  // seq_no: 1002
  // social_facebook: "https://bbb"
  // social_instgram: "https://ccc"
  // social_kakao: "https://eeee"
  // social_naver: "https://dddd"
  // social_youtube: "https://aaa"

  // const number = term.keys()
  // { value: 1, done: false }
  // number.next().value ===  1

  return (
    <div className={styles.con}>
      <div>
        <div className={styles.footer}>
          <div>
            {/* <img src={sampleimg} alt={"titleLogo"} /> */}
            <Logo w={105.38} h={23.2} />
          </div>
          <div className={styles.peachinfo}>
            <div>
              <div
                className={styles.link}
                onClick={() => navigate("/term/intro/")}
              >
                피치서가 소개
              </div>
              <div
                className={styles.link}
                onClick={() =>
                  navigate(`/term/?number=${Array.from(term)[0][0]}`)
                }
              >
                약관안내
              </div>
              <div
                className={styles.link}
                onClick={() => {
                  navigate("/member/signUp/group")
                }}
              >
                파트너쉽 제휴
              </div>
            </div>
            <div className={styles.family}>
              <div>패밀리 사이트</div>
              <img src={downIcon} alt="" />
              <div className={styles.family_contents}>
                <div>
                  {familes?.map(v => (
                    <a href={v.link} key={v.seq_no}>
                      {v.site}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.info}>
            <div>
              <p>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#333333",
                  }}
                >
                  {footer?.company_name}
                </span>
                <br />
                {footer?.company_address}{" "}
                <span style={{ marginLeft: "10px" }}>대표자 :</span>{" "}
                {footer?.company_ceo}
                <br />
                사업자등록번호 : {footer?.company_reg_number}{" "}
                <span style={{ marginLeft: "17px" }}>개인정보보호책임자</span>:
                {footer.company_officer}
                <br />
                관리자 이메일 : {footer?.company_email}
              </p>
            </div>
            <div>
              <p>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#333333",
                  }}
                >
                  고객센터
                </span>
                <br />
                TEL : &nbsp;
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    color: "#E95C63",
                    marginRight: "10px",
                  }}
                >
                  &nbsp;
                  {footer?.customer_phone}
                </span>
                {footer?.customer_operation}
                <br />
                FAX : {footer.customer_fax} 통신판매업번호 :
                {footer?.customer_sale_number}
                <br />
                관리자 이메일: {footer?.customer_email}
              </p>
            </div>
          </div>

          <div className={styles.notice}>
            <div>{footer?.inform_copyright}</div>
            <div className={styles.imgBox} style={{ display: "none" }}>
              <a href={footer?.social_youtube}>
                <img src={youtube} alt="" />
              </a>
              <a href={footer?.social_instgram}>
                <img src={ins} alt="" />
              </a>
              <a href={footer?.social_facebook}>
                <img src={face} alt="" />
              </a>
              <a href={footer?.social_kakao}>
                <img src={kao} alt="" />
              </a>
              <a href={footer?.social_naver}>
                <img src={naver} alt="" />
              </a>
            </div>
          </div>
          <p className={styles.copyMsg}>
            Copyright Peach Seoga korea ALL Rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
