// extracted by mini-css-extract-plugin
export var con = "footer-module--con--10xHE";
export var footer = "footer-module--footer--nXiH7";
export var peachinfo = "footer-module--peachinfo--2J7Ed";
export var info = "footer-module--info--3ZQIx";
export var family = "footer-module--family--2L86u";
export var family_contents = "footer-module--family_contents--1NoHa";
export var notice = "footer-module--notice--1pWfC";
export var imgBox = "footer-module--imgBox--YopLl";
export var copyMsg = "footer-module--copyMsg--1iMhN";
export var link = "footer-module--link--FKxTd";