import React, { useState, useContext, useEffect } from "react"
import * as styles from "./pagination.module.css"

import next_10 from "images/next_10.png"
import next_1 from "images/next_1.png"
import prev_10 from "images/prev_10.png"
import prev_1 from "images/prev_1.png"
import { navigate } from "gatsby"

const Pagination = props => {
  // props.total = 토탈 글 갯수
  // props.pagePostSize = 한페이지당 보여지는 글수
  // props.pageSize = 보여줄 블락의 수

  const [totalPages, setTotalPages] = useState([])
  useEffect(() => {
    if (props.total && props.pagePostSize)
      setTotalPages(
        Array.from(
          { length: Math.ceil(props.total / props.pagePostSize) },
          (_, i) => i + 1
        )
      )
  }, [props])

  const test = p => {
    if (!props.Nav) {
      const host = window.location.host
      const search = window.location.search

      if (search.includes("?") || search.includes("&")) {
        if (search.includes("tag") || search.includes("keyword")) {
          return
        } else if (search.includes("page")) {
          navigate(search.replace(search.split("page")[1], `=${p}`))
        } else {
          navigate(`${host}&page=${p}`)
        }
      } else {
        navigate(`?&page=${p}`)
      }
    }
  }

  const [pageSlice, setPageSlice] = useState(0)

  const currentPages = totalPages.slice(pageSlice, pageSlice + props.pageSize)

  const isPrevTen = Math.ceil(props.currentPage / props.pageSize) !== 1
  const isPrev = props.currentPage !== 1
  const isNextTen =
    currentPages[currentPages.length - 1] + 1 <=
    totalPages[totalPages.length - 1]

  const isNext = props.currentPage + 1 <= totalPages[totalPages.length - 1]
  const onPrevTen = () => {
    setPageSlice(pageSlice - props.pageSize)
    props.setCurrentPage(currentPages[0] - props.pageSize)
    test(currentPages[0] - props.pageSize)
  }
  const onNextTen = () => {
    setPageSlice(pageSlice + props.pageSize)
    props.setCurrentPage(currentPages[currentPages.length - 1] + 1)
    test(currentPages[currentPages.length - 1] + 1)
  }

  const onPrev = () => {
    if (props.currentPage === currentPages[0])
      setPageSlice(props.currentPage - props.pageSize - 1)

    props.setCurrentPage(props.currentPage - 1)
    test(props.currentPage - 1)
  }

  const onNext = () => {
    if (props.currentPage === currentPages[currentPages.length - 1]) {
      setPageSlice(props.currentPage)
    }

    props.setCurrentPage(props.currentPage + 1)
    test(props.currentPage + 1)
  }

  const onPage = ({ target: { textContent } }) => {
    props.setCurrentPage(parseInt(textContent))
    test(parseInt(textContent))
  }
  return (
    <div className={styles.con}>
      {isPrevTen ? (
        <div onClick={onPrevTen}>
          <img src={prev_10} className={styles.arrowBtn} />
        </div>
      ) : (
        <div />
      )}
      {isPrev ? (
        <div onClick={onPrev}>
          <img src={prev_1} className={styles.arrowBtn} />
        </div>
      ) : (
        <div />
      )}
      {currentPages.map((v, i) => (
        <div
          key={i}
          className={v === props.currentPage ? styles.on : styles.off}
          onClick={onPage}
        >
          {v}
        </div>
      ))}
      {isNext ? (
        <div onClick={onNext}>
          <img src={next_1} className={styles.arrowBtn} />
        </div>
      ) : (
        <div />
      )}
      {isNextTen ? (
        <div onClick={onNextTen}>
          <img src={next_10} className={styles.arrowBtn} />
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

export default Pagination
