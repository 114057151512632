import React, { useEffect, useState } from "react"
import Logo from "components/tools/logo.js"
import { navigate } from "gatsby-link"
import Footer from "components/common/footer"
import * as styles from "./layout.module.css"
import api from "apis"
import peachIcon from "images/peachIcons.png"

import { profiles } from "utils/data"
const Layout = props => {
  // const Profile = useSelector(
  //   ({ ProfileReducer: { Profile } }) => Profile,
  //   shallowEqual
  // )
  const [profile, setProfile] = useState({})

  useEffect(() => {
    ;(async () => {
      const result = await api.readProfile()
      setProfile({
        icon: result.icon_no,
        name: result.name,
      })
    })()
  }, [])
  //프로파일 업데이트
  //수업나눔

  return (
    <div className={styles.con}>
      <div className={styles.header}>
        <div
          onClick={() => {
            navigate("/front")
          }}
        >
          <Logo w={188.15} h={41.43} customStyle={props.customStyle} />
        </div>
      </div>
      <div className={styles.subtitle}>고객센터</div>
      <div className={styles.banner}>
        <div>
          <div className={styles.profile}>
            <img src={profiles.get(profile?.icon)} alt="icon" />
            <div style={{ marginLeft: "10px" }}>
              {profile?.name}님, 무엇을 도와드릴까요?
            </div>
          </div>
          <div className={styles.btnCon}>
            <div
              onClick={() => {
                navigate("/cs/list")
              }}
            >
              FAQ
            </div>
            <div
              onClick={() => {
                navigate("/cs/contact/request")
              }}
            >
              1:1 문의하기
            </div>
            <div
              onClick={() => {
                navigate("/cs/contact/list")
              }}
            >
              나의 문의 내역
            </div>
          </div>
        </div>
      </div>
      <div className={styles.main}>{props.children}</div>
      <Footer />
    </div>
  )
}

export default Layout
